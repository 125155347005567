import { graphql, useStaticQuery } from "gatsby";

export const useFetchCoalList = () => {
  const data = useStaticQuery(graphql`
    query CoalList {
      coalList {
        id
        data {
          coalList {
            data {
              stockStatus
              sortIndex
              productUrl
              productName
              mine
              mineId
            }
            updatedAt
            numOfRound
          }
        }
      }
    }
  `);

  return data.coalList.data.coalList;
};
