import React from "react";

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  className: string;
}

export default function BaseTooltip({
  children,
  content,
  className,
}: TooltipProps) {
  return (
    <div className={`has-tooltip ${className}`}>
      <span className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 max-w-xs top-12">
        {content}
      </span>
      {children}
    </div>
  );
}
