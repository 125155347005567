import React, { useEffect, useState } from "react";

import AdSense from "react-adsense";
import { AD_CLIENT, AD_SLOT } from "../../constant";
import CoalListItem from "./CoalListItem";

import { useInterval } from "./useInterval";

import { orderBy, sample } from "lodash";
import useLocalStorage from "../../hooks/useLocalStorage";
import { initialStarred } from "./constant";
import { ToastContainer, toast } from "react-toastify";
import { useFetchCoalList } from "./useFetchCoalList";
import axios from "axios";
import dayjs from "dayjs";


dayjs.locale("pl");

const listApi = [
  "https://api.sprawdzwegiel.pl",
  "https://api3.sprawdzwegiel.pl",
];

let userHasAdblock = false;

function adblockDetected() {
  return new Promise(function (resolve, reject) {
      var script = document.createElement('script');

      script.onload = function() {
        resolve(false);
      }

      script.onerror = function() {
          resolve(true);
      }

      script.src = 'https://static.hotjar.com/c/hotjar-2994070.js?sv=6';
      document.body.appendChild(script);
  });
}

const intervalTime = userHasAdblock ? 90000 : 10000;

const sortByAvailable = (array: []) => {
  return orderBy(array, ["stockStatus", "sortIndex"], ["desc", "asc"]);
};

const sortByStarred = (array: [], starredList) => {
  if(userHasAdblock) {
    return array;
  }
  const tempArray = [];
  array.map((item: any) => {
    const isStarred = starredList[Number(item.productId)];
    isStarred && tempArray.push(item);
  });
  array.map((item: any) => {
    const isStarred = starredList[Number(item.productId)];
    !isStarred && tempArray.push(item);
  });

  return tempArray;
};

const getNameSession = (day: string) => {
  const dayToNumber = Number(day);

  if(dayToNumber === 1 || dayToNumber === 3 || dayToNumber === 5) {
    return "tylko miał węglowy"
  }
  if(dayToNumber === 2 || dayToNumber === 4) {
    return "tylko węgiel konfekcjonowany (workowany)"
  }
  return false;
};


const CoalList = () => {
  const staticData = useFetchCoalList();

  const [isLoading, setIsLoading] = useState(false);
  const [coalList, setCoalList] = useState<any>({ ...staticData });
  const [starredList, setStarredList] = useLocalStorage(
    "starred",
    initialStarred
  );

  const updateStarredList = (key: number, value: boolean) => {
    if(userHasAdblock) {
      toast.warn("Dodawanie do ulubionych zostało zablokowane przez adblocka!");
      return;
    }
    const tempStarred = { ...starredList, [key]: value };

    setStarredList(tempStarred);
    if (value) {
      toast.success("Pomyślnie dodano do ulubionych!");
      return;
    }
    toast.success("Pomyślnie usunięto z ulubionych!");
  };
  useEffect(( ) => {
    if(window) {
      adblockDetected().then(function(result) {
        userHasAdblock = Boolean(result);
    });
    
    }
  },[])

  useInterval(() => {
    const linkApi = sample(listApi);
    axios
    .get(`${linkApi}/coal-stock.json?${new Date().toISOString().slice(0, -8)}`)
    .then(response => {
      const { data } = response;
      if(data) {
        setCoalList(data);
      }
    }).catch(() => {
      const linkApi = sample(listApi);
      axios
      .get(`${linkApi}/coal-stock.json?${new Date().toISOString().slice(0, -8)}`)
      .then(response => {
        const { data } = response;
        if(data) {
          setCoalList(data);
        }
      })
    });
  }, intervalTime);

  useEffect(() => {
    const linkApi = sample(listApi);
    setIsLoading(true);
    axios
      .get(`${linkApi}/coal-stock.json?${new Date().toISOString().slice(0, -8)}`)
      .then(response => {
        const { data } = response;
        if(data) {
          setCoalList(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const updatedAtPure = dayjs(coalList.updatedAt);
  const currentTime = dayjs(new Date());
  const updatedAtFormatted = updatedAtPure
    .locale("pl")
    .format("DD.MM.YYYY, HH:mm:ss");

  const timeDiffCurrentToLastUpdated = Math.abs(
    Number(currentTime.diff(updatedAtPure, "minutes"))
  );

  const sortedByAvailable = sortByAvailable(coalList.data);
  //@ts-ignore
  const sortedByStarred = sortByStarred(sortedByAvailable, starredList);
  const nameSession = getNameSession(currentTime.format("d"));
  
  if (coalList.updatedAt) {
    return (
      <>
        <div className="bg-white lg:min-w-0 lg:flex-1">
          <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
            <p className="relative leading-tight text-gray-900">
              Dołącz do społeczności&nbsp;
              <a
                className="text-indigo-500 hover:underline"
                target="_blank"
                rel="noopener, noreferrer, nofollow"
                href="https://www.facebook.com/groups/699977634408853"
              >
                sprawdź węgiel na facebooku
              </a>
              &nbsp;i zyskaj cenne wskazówki na temat zakupów w PGG.
            </p>
          </div>
          <div className="max-w-3xl px-4 sm:px-6 lg:px-8 mt-5">
            {coalList.numOfRound !== "out" ? (
              <>
               {nameSession ? <p className="relative leading-tight text-gray-900">
                  Podczas dzisiejszej sesji może być wystawiany <strong>{nameSession}</strong>.
                </p> : null}
                <p className="relative leading-tight text-gray-900">
                  Aktualizacja: {updatedAtFormatted}
                </p>
                {timeDiffCurrentToLastUpdated > 5 && timeDiffCurrentToLastUpdated < 40 ? (
                  <div
                    className="bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full"
                    role="alert"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="exclamation-triangle"
                      className="w-4 h-4 mr-2 fill-current"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                      ></path>
                    </svg>
                    Nie zaktualizowano stanów od co najmniej{" "}
                    {timeDiffCurrentToLastUpdated} minut!
                    <br />
                    Zalecane odświeżenie ręczne sklepu.
                  </div>
                ) : null}
              </>
            ) : (
              <p className="relative leading-tight text-gray-900">
                Aktualizacja pojawi się w trakcie sesji sprzedażowej{" "}
                <span className="text-sm">
                  <br />
                  (w przypadku braku aktualizacji użyj ctrl+shift+f5)
                </span>
              </p>
            )}
          </div>
          <ul
            role="list"
            className="relative z-0 divide-y divide-gray-200 border-gray-200 ml-0"
          >
            {isLoading && (
              <div className="items-center absolute inset-x-0 top-3 lg:top-96 z-40">
                <div className="w-16 h-16 border-4 border-gray-700 border-dotted rounded-full animate-spin m-auto"></div>
                <p className="mx-auto text-gray-700 font-bold text-center">
                  Ładowanie ...
                </p>
              </div>
            )}

            <div className={`${isLoading && "animate-pulse"}`}>
              {sortedByStarred.map(
                (item: any, index: React.Key | null | undefined) => {
                  const isStarred = starredList[Number(item.productId)];

                  return (
                    <React.Fragment key={item.productUrl}>
                      {Boolean(Number(index) % 4 === 0) && index !== 0 && (
                        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
                          <AdSense.Google
                            client={AD_CLIENT}
                            slot={AD_SLOT}
                            style={{ display: "block" }}
                            format="auto"
                            responsive="true"
                            layoutKey="-gw-1+2a-9x+5c"
                          />
                        </div>
                      )}
                      <CoalListItem
                        numOfRound={coalList.numOfRound}
                        isStarred={!userHasAdblock && isStarred}
                        onSetStarredItem={updateStarredList}
                        {...item}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </ul>
        </div>
        <ToastContainer />
      </>
    );
  }
  return "Ładowanie...";
};

export default React.memo(CoalList);
