import "dayjs/locale/pl";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import StarIcon from "@heroicons/react/outline/StarIcon";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import BaseTooltip from "../BaseTooltip/BaseTooltip";
import { getHoursRound } from "../../helpers/roundUtils";
import { configDayJs } from "./constant";

dayjs.locale("pl");
dayjs.extend(relativeTime, configDayJs);
interface ListItemProps {
  numOfRound: number;
  productId: string;
  productName: string;
  productUrl: string;
  stockStatus: boolean;
  price: string;
  mine: string;
  createdAt: number | null;
  updatedAt: number | null;
  isStarred: boolean;
  onSetStarredItem: (key: number, value: boolean) => void;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PGG_DOMAIN = "https://sklep.pgg.pl";

const TooltipContent = ({
  hasUpdatedAt,
  numOfRound,
}: {
  hasUpdatedAt: boolean;
  numOfRound: number;
}) => {
  if (hasUpdatedAt) {
    return <p>Ten status informuje jak dawno została zmieniona dostępność.</p>;
  }
  return (
    <p>W aktualnej sesji sprzedaży nie wykryto dostępności tego produktu.</p>
  );
};

const ListItem = ({
  numOfRound,
  productId,
  productName,
  productUrl,
  stockStatus: isAvailable,
  price,
  mine,
  updatedAt,
  createdAt,
  isStarred,
  onSetStarredItem,
}: ListItemProps) => {
  const stockStatusText = isAvailable ? "Dostępny" : "Niedostępny";
  const url = PGG_DOMAIN + productUrl;

  const productNameArray = productName.split("\n");
  const hasUpdatedAt = Boolean(updatedAt);
  const updatedAtDate = isAvailable
    ? dayjs(createdAt).locale("pl")
    : dayjs(updatedAt).locale("pl");
  const updatedAgo = hasUpdatedAt && updatedAtDate.fromNow(true);

  const toggleStarred = () => {
    onSetStarredItem(Number(productId), !isStarred);
  };

  return (
    <li
      key={productId}
      className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
    >
      <div className="flex items-center items-stretch justify-between space-x-4">
        {/* Repo name and link */}
        <div className="min-w-0 space-y-3">
          <div className="flex items-center space-x-3">
            <span
              className={classNames(
                isAvailable ? "bg-green-100" : "bg-gray-100",
                "h-4 w-4 rounded-full flex items-center justify-center"
              )}
              aria-hidden="true"
            >
              <span
                className={classNames(
                  isAvailable ? "bg-green-400" : "bg-gray-400",
                  "h-2 w-2 rounded-full"
                )}
              />
            </span>

            <span
              className={classNames(
                isAvailable
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800 ",
                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
              )}
            >
              {stockStatusText}
            </span>

            <BaseTooltip
              content={
                <TooltipContent
                  numOfRound={numOfRound}
                  hasUpdatedAt={hasUpdatedAt}
                />
              }
              className="inline-flex"
            >
              <button
                type="button"
                className={classNames(
                  hasUpdatedAt
                    ? "bg-blue-100 text-blue-800"
                    : "bg-gray-100 text-gray-800 ",
                  "items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                )}
              >
                {hasUpdatedAt ? `od ${updatedAgo}` : "Brak danych"}
              </button>
            </BaseTooltip>
          </div>
          <div className="flex space-x-2.5">
            <button
              onClick={toggleStarred}
              type="button"
              className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="sr-only">
                {isStarred ? "Add to favorites" : "Remove from favorites"}
              </span>
              <StarIcon
                className={classNames(
                  isStarred
                    ? "text-yellow-300 hover:text-yellow-400"
                    : "text-gray-300 hover:text-gray-400",
                  "h-5 w-5"
                )}
                aria-hidden="true"
              />
            </button>
            <a
              href={url}
              className="relative group flex flex-col items-left"
              target="_blank"
              rel="noopener, noreferrer, nofollow"
            >
              {productNameArray.map((productNameItem: string, index) => (
                <span
                  key={index}
                  className="text-base text-gray-500 group-hover:text-gray-900 font-medium truncate block ml-0"
                >
                  {productNameItem.replace("\n", "")}
                </span>
              ))}
            </a>
          </div>
        </div>

        <div className="sm:hidden flex flex-col justify-end">
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>

        {/* Repo meta info */}
        <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
          <p className="flex items-center space-x-4">
            <a
              href={url}
              className="relative text-sm text-gray-500 hover:text-gray-900 font-medium"
              target="_blank"
              rel="noopener, noreferrer, nofollow"
            >
              Przejdź do produktu
            </a>
          </p>
          <p className="flex text-gray-500 text-sm space-x-2">
            <span>
              {updatedAt &&
                `Zaaktualizowano ${updatedAtDate.format(
                  "YYYY-MM-DD, HH:mm:ss"
                )}`}
            </span>
          </p>
        </div>
      </div>
    </li>
  );
};

export default ListItem;
