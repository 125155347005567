import React from "react";

import CoalList from "../components/CoalList/CoalList";
import BaseLayout from "../layout/BaseLayout";

const Index: React.FC = () => {
  return (
    <BaseLayout
      header="Aktualny stan dostępności węgla ze strony PGG"
      headerSeo="Sprawdź dostepność wegla ze strony PGG"
      description="Od 15.11 towar w sklepie jest wystawiany od poniedziałku do piątku w dwóch turach: od 16:00 i od 18:00. Poniżej prezentujemy aktualne stany magazynowe na sklepie."
    >
      <CoalList />
    </BaseLayout>
  );
};

export default Index;
